<script>
import ApiService from "@/services/api.service";
import template from "../../../dashboard/template.vue";
import { mapGetters } from "vuex";
export default {
  components: { template },
  data() {
    return {
      products: {},
      organization_products: {},
      productList: null,
      productType: "",
      productId: 0,
      preference: {},
      form: {
        allow_hibah: false,
        allow_wasiat: false,
        allow_trust: false,
      },
    };
  },
  mounted() {
    console.log(this.organizationId);
    this.getPreference();
    this.getProducts();
    this.getOrganizationProduct();
  },
  methods: {
    getPreference() {
      ApiService.get(`organization/${this.organizationId}/preference`).then(
        (response) => {
          console.log("Preference", response.data.preference.id);
          this.preference = response.data.preference ?? {};
          this.form.allow_wasiat = this.preference.allow_wasiat;
          this.form.allow_hibah = this.preference.allow_hibah;
          this.form.allow_trust = this.preference.allow_trust;
        }
      );
    },
    getOrganizationProduct() {
      ApiService.get(
        `organization-product?organization_id=${this.organizationId}`
      ).then((response) => {
        this.organization_products = response.data.organization_product;

        // if (product_array.length > 0) {
        //   this.organization_products = product_array.filter(
        //     (item) => item.product_id == productId
        //   );
        // }
        console.log("Organization Product", this.organization_products.length);
      });
    },
    updatePreference() {
      this.form._method = "PATCH";
      ApiService.post(`preference/${this.preference.id}`, this.form)
        .then((response) => {
          this.getPreference();
        })
        .catch((error) => {
          this.isSubmit = false;
        });
    },
    createOrganizationProduct(productId) {
      if (this.organization_products.length != this.productList.length) {
        ApiService.get(
          `organization-product?organization_id=${this.organizationId}&product_id=${productId}`
        )
          .then((response) => {
            if (this.organization_products.length != this.productList.length) {
              this.getOrganizationProduct();
            }
          })
          .catch((error) => {
            this.isSubmit = false;
          });
      }
    },
    getProducts() {
      ApiService.get(`product`).then((response) => {
        this.productList = response.data.products;
        this.products = this.productList.reduce((r, a) => {
          r[a.category] = [...(r[a.category] || []), a];
          return r;
        }, {});
        this.products = Object.entries(this.products);
        console.log("Products", this.productList.length);

        this.productList.forEach((item) => {
          this.createOrganizationProduct(item.id);
        });
      });
    },
    edit(productId) {
      this.productId = productId;
      if (this.productId == 13) {
        this.productType = "wasiat";
      } else if (this.productId % 2 != 0 || productId == 12) {
        this.productType = "hibah";
        console.log("productId", productId);
      } else if (this.productId % 2 == 0 || productId == 11) {
        this.productType = "trust";
      }

      var name = "organization.product." + this.productType + ".edit";

      this.$router.push({
        name: name,
        params: {
          organization_id: this.organizationId,
          product_id: this.productId,
        },
      });
    },
  },
  computed: {
    organizationId() {
      return this.$route.params.organization_id;
    },
    organizationType() {
      return this.organization.type;
    },
    ...mapGetters(["userRole"]),
  },
  props: {
    organization: {
      type: Object,
    },
  },
};
</script>

<template>
  <div>
    <div v-if="['Staff'].includes(userRole)" class="row">
      <div class="form-group col-md-4">
        <label for style="text-transform: capitalize">
          1. {{ $t("navbar.testament") }}
          <label class="switch">
            <input
              type="checkbox"
              @change="updatePreference"
              value="1"
              v-model="form.allow_wasiat"
            />
            <span class="slider round"></span>
          </label>
        </label>
      </div>
      <div class="form-group col-md-4">
        <label for style="text-transform: capitalize">
          2. {{ $t("navbar.trust") }}</label
        >
        <label class="switch">
          <input
            type="checkbox"
            @change="updatePreference"
            value="1"
            v-model="form.allow_hibah"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="form-group col-md-4">
        <label for style="text-transform: capitalize">
          3. {{ $t("trusts") }}</label
        >
        <label class="switch">
          <input
            type="checkbox"
            @change="updatePreference"
            value="1"
            v-model="form.allow_trust"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="row" v-if="preference.allow_wasiat == 1">
      <div class="col-md-12">
        <hr />
        <h5>{{ $t("navbar.testament") }}</h5>
      </div>
      <div class="col-md-12 mb-3">
        <div class="table-responsive bg-white shadow mt-4">
          <table class="table mb-0 table-center table-sm">
            <thead class="bg-light">
              <tr>
                <th>{{ $t("index") }}</th>
                <th style="width: 40%">{{ $t("preference.product-name") }}</th>
                <th style="width: 10%">{{ $t("status") }}</th>
                <th style="width: 40%">{{ $t("org.nav4") }}</th>
                <th v-if="['Staff'].includes(userRole)" class="text-right">
                  {{ $t("action") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in products[0][1]" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <template
                  v-for="(organization_product, index) in organization_products"
                >
                  <td
                    :key="index"
                    v-if="organization_product.product_id == item.id"
                  >
                    <span
                      class="badge badge-success"
                      v-if="organization_product.status == 1"
                      >{{ $t("active") }}</span
                    >
                    <span class="badge badge-danger" v-else>{{
                      $t("inactive")
                    }}</span>
                  </td>
                  <td
                    :key="'A' + index"
                    v-if="organization_product.product_id == item.id"
                  >
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.allow_public != 0"
                      >{{ $t("preference.public") }}</span
                    >
                  </td>
                </template>
                <td v-if="['Staff'].includes(userRole)" class="text-right">
                  <button
                    type="button"
                    @click="edit(item.id)"
                    class="btn btn-primary btn-sm"
                  >
                    {{ $t("update") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row" v-if="preference.allow_hibah == 1">
      <div class="col-md-12">
        <hr />
        <h4>{{ $t("navbar.trust") }}</h4>
      </div>
      <div class="col-md-12 mb-3">
        <div class="table-responsive bg-white shadow mt-4">
          <table class="table mb-0 table-center table-sm">
            <thead class="bg-light">
              <tr>
                <th>{{ $t("index") }}</th>
                <th style="width: 40%">{{ $t("preference.product-name") }}</th>
                <th style="width: 10%">{{ $t("status") }}</th>
                <th style="width: 40%">{{ $t("org.nav4") }}</th>
                <th v-if="['Staff'].includes(userRole)" class="text-right">
                  {{ $t("action") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in products[1][1]" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <template
                  v-for="(organization_product, index) in organization_products"
                >
                  <td
                    :key="index"
                    v-if="organization_product.product_id == item.id"
                  >
                    <span
                      class="badge badge-success"
                      v-if="organization_product.status == 1"
                      >{{ $t("active") }}</span
                    >
                    <span class="badge badge-danger" v-else>{{
                      $t("inactive")
                    }}</span>
                  </td>
                  <td
                    :key="'A' + index"
                    v-if="organization_product.product_id == item.id"
                  >
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.allow_public != 0"
                      >{{ $t("preference.public") }}</span
                    >&nbsp;
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.allow_payment != 0"
                      >{{ $t("accept-payment") }}</span
                    >&nbsp;
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.receiver_count != 0"
                      >{{ organization_product.receiver_count }} {{ $t("beneficiary") }}</span
                    >&nbsp;
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.caretaker_count != 0"
                      >{{ organization_product.caretaker_count }} {{ $t("caretaker") }}</span
                    >&nbsp;
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.moveable_property_count != 0"
                      >{{ organization_product.moveable_property_count }} {{ $t("mov-prop") }}</span
                    >&nbsp;
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.immoveable_property_count != 0"
                      >{{ organization_product.immoveable_property_count }} {{ $t("immov-prop") }}</span
                    >
                  </td>
                </template>
                <td v-if="['Staff'].includes(userRole)" class="text-right">
                  <button
                    type="button"
                    @click="edit(item.id)"
                    class="btn btn-primary btn-sm"
                  >
                    {{ $t("update") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row" v-if="preference.allow_trust == 1">
      <div class="col-md-12">
        <hr />
        <h4>{{ $t("trusts") }}</h4>
      </div>
      <div class="col-md-12 mb-3">
        <div class="table-responsive bg-white shadow mt-4">
          <table class="table mb-0 table-center table-sm">
            <thead class="bg-light">
              <tr>
                <th>{{ $t("index") }}</th>
                <th style="width: 40%">{{ $t("preference.product-name") }}</th>
                <th style="width: 10%">{{ $t("status") }}</th>
                <th style="width: 40%">{{ $t("org.nav4") }}</th>
                <th v-if="['Staff'].includes(userRole)" class="text-right">
                  {{ $t("action") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in products[2][1]" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <template
                  v-for="(organization_product, index) in organization_products"
                >
                  <td
                    :key="index"
                    v-if="organization_product.product_id == item.id"
                  >
                    <span
                      class="badge badge-success"
                      v-if="organization_product.status == 1"
                      >{{ $t("active") }}</span
                    >
                    <span class="badge badge-danger" v-else>{{
                      $t("inactive")
                    }}</span>
                  </td>
                  <td
                    :key="'A' + index"
                    v-if="organization_product.product_id == item.id"
                  >
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.allow_public != 0"
                      >{{ $t("preference.public") }}</span
                    >&nbsp;
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.allow_payment != 0"
                      >{{ $t("accept-payment") }}</span
                    >&nbsp;
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.receiver_count != 0"
                      >{{ organization_product.receiver_count }} {{ $t("beneficiary") }}</span
                    >&nbsp;
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.caretaker_count != 0"
                      >{{ organization_product.caretaker_count }} {{ $t("caretaker") }}</span
                    >&nbsp;
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.moveable_property_count != 0"
                      >{{ organization_product.moveable_property_count }} {{ $t("mov-prop") }}</span
                    >&nbsp;
                    <span
                      class="badge badge-secondary"
                      v-if="organization_product.immoveable_property_count != 0"
                      >{{ organization_product.immoveable_property_count }} {{ $t("immov-prop") }}</span
                    >
                  </td>
                </template>
                <td v-if="['Staff'].includes(userRole)" class="text-right">
                  <button
                    v-if="['Staff'].includes(userRole)"
                    type="button"
                    @click="edit(item.id)"
                    class="btn btn-primary btn-sm"
                  >
                    {{ $t("update") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
