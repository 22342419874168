var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [['Staff'].includes(_vm.userRole) ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(" 1. " + _vm._s(_vm.$t("navbar.testament")) + " "), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.allow_wasiat,
      expression: "form.allow_wasiat"
    }],
    attrs: {
      "type": "checkbox",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.allow_wasiat) ? _vm._i(_vm.form.allow_wasiat, "1") > -1 : _vm.form.allow_wasiat
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.form.allow_wasiat,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "allow_wasiat", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "allow_wasiat", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "allow_wasiat", $$c);
        }
      }, _vm.updatePreference]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(" 2. " + _vm._s(_vm.$t("navbar.trust")))]), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.allow_hibah,
      expression: "form.allow_hibah"
    }],
    attrs: {
      "type": "checkbox",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.allow_hibah) ? _vm._i(_vm.form.allow_hibah, "1") > -1 : _vm.form.allow_hibah
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.form.allow_hibah,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "allow_hibah", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "allow_hibah", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "allow_hibah", $$c);
        }
      }, _vm.updatePreference]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(" 3. " + _vm._s(_vm.$t("trusts")))]), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.allow_trust,
      expression: "form.allow_trust"
    }],
    attrs: {
      "type": "checkbox",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.allow_trust) ? _vm._i(_vm.form.allow_trust, "1") > -1 : _vm.form.allow_trust
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.form.allow_trust,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "allow_trust", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "allow_trust", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "allow_trust", $$c);
        }
      }, _vm.updatePreference]
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]) : _vm._e(), _vm.preference.allow_wasiat == 1 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('hr'), _c('h5', [_vm._v(_vm._s(_vm.$t("navbar.testament")))])]), _c('div', {
    staticClass: "col-md-12 mb-3"
  }, [_c('div', {
    staticClass: "table-responsive bg-white shadow mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center table-sm"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.product-name")))]), _c('th', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('th', {
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav4")))]), ['Staff'].includes(_vm.userRole) ? _c('th', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.products[0][1], function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(item.name))]), _vm._l(_vm.organization_products, function (organization_product, index) {
      return [organization_product.product_id == item.id ? _c('td', {
        key: index
      }, [organization_product.status == 1 ? _c('span', {
        staticClass: "badge badge-success"
      }, [_vm._v(_vm._s(_vm.$t("active")))]) : _c('span', {
        staticClass: "badge badge-danger"
      }, [_vm._v(_vm._s(_vm.$t("inactive")))])]) : _vm._e(), organization_product.product_id == item.id ? _c('td', {
        key: 'A' + index
      }, [organization_product.allow_public != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(_vm.$t("preference.public")))]) : _vm._e()]) : _vm._e()];
    }), ['Staff'].includes(_vm.userRole) ? _c('td', {
      staticClass: "text-right"
    }, [_c('button', {
      staticClass: "btn btn-primary btn-sm",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.edit(item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")])]) : _vm._e()], 2);
  }), 0)])])])]) : _vm._e(), _vm.preference.allow_hibah == 1 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('hr'), _c('h4', [_vm._v(_vm._s(_vm.$t("navbar.trust")))])]), _c('div', {
    staticClass: "col-md-12 mb-3"
  }, [_c('div', {
    staticClass: "table-responsive bg-white shadow mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center table-sm"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.product-name")))]), _c('th', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('th', {
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav4")))]), ['Staff'].includes(_vm.userRole) ? _c('th', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.products[1][1], function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(item.name))]), _vm._l(_vm.organization_products, function (organization_product, index) {
      return [organization_product.product_id == item.id ? _c('td', {
        key: index
      }, [organization_product.status == 1 ? _c('span', {
        staticClass: "badge badge-success"
      }, [_vm._v(_vm._s(_vm.$t("active")))]) : _c('span', {
        staticClass: "badge badge-danger"
      }, [_vm._v(_vm._s(_vm.$t("inactive")))])]) : _vm._e(), organization_product.product_id == item.id ? _c('td', {
        key: 'A' + index
      }, [organization_product.allow_public != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(_vm.$t("preference.public")))]) : _vm._e(), _vm._v(" "), organization_product.allow_payment != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(_vm.$t("accept-payment")))]) : _vm._e(), _vm._v(" "), organization_product.receiver_count != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(organization_product.receiver_count) + " " + _vm._s(_vm.$t("beneficiary")))]) : _vm._e(), _vm._v(" "), organization_product.caretaker_count != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(organization_product.caretaker_count) + " " + _vm._s(_vm.$t("caretaker")))]) : _vm._e(), _vm._v(" "), organization_product.moveable_property_count != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(organization_product.moveable_property_count) + " " + _vm._s(_vm.$t("mov-prop")))]) : _vm._e(), _vm._v(" "), organization_product.immoveable_property_count != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(organization_product.immoveable_property_count) + " " + _vm._s(_vm.$t("immov-prop")))]) : _vm._e()]) : _vm._e()];
    }), ['Staff'].includes(_vm.userRole) ? _c('td', {
      staticClass: "text-right"
    }, [_c('button', {
      staticClass: "btn btn-primary btn-sm",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.edit(item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")])]) : _vm._e()], 2);
  }), 0)])])])]) : _vm._e(), _vm.preference.allow_trust == 1 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('hr'), _c('h4', [_vm._v(_vm._s(_vm.$t("trusts")))])]), _c('div', {
    staticClass: "col-md-12 mb-3"
  }, [_c('div', {
    staticClass: "table-responsive bg-white shadow mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center table-sm"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.product-name")))]), _c('th', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('th', {
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav4")))]), ['Staff'].includes(_vm.userRole) ? _c('th', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.products[2][1], function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(item.name))]), _vm._l(_vm.organization_products, function (organization_product, index) {
      return [organization_product.product_id == item.id ? _c('td', {
        key: index
      }, [organization_product.status == 1 ? _c('span', {
        staticClass: "badge badge-success"
      }, [_vm._v(_vm._s(_vm.$t("active")))]) : _c('span', {
        staticClass: "badge badge-danger"
      }, [_vm._v(_vm._s(_vm.$t("inactive")))])]) : _vm._e(), organization_product.product_id == item.id ? _c('td', {
        key: 'A' + index
      }, [organization_product.allow_public != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(_vm.$t("preference.public")))]) : _vm._e(), _vm._v(" "), organization_product.allow_payment != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(_vm.$t("accept-payment")))]) : _vm._e(), _vm._v(" "), organization_product.receiver_count != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(organization_product.receiver_count) + " " + _vm._s(_vm.$t("beneficiary")))]) : _vm._e(), _vm._v(" "), organization_product.caretaker_count != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(organization_product.caretaker_count) + " " + _vm._s(_vm.$t("caretaker")))]) : _vm._e(), _vm._v(" "), organization_product.moveable_property_count != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(organization_product.moveable_property_count) + " " + _vm._s(_vm.$t("mov-prop")))]) : _vm._e(), _vm._v(" "), organization_product.immoveable_property_count != 0 ? _c('span', {
        staticClass: "badge badge-secondary"
      }, [_vm._v(_vm._s(organization_product.immoveable_property_count) + " " + _vm._s(_vm.$t("immov-prop")))]) : _vm._e()]) : _vm._e()];
    }), ['Staff'].includes(_vm.userRole) ? _c('td', {
      staticClass: "text-right"
    }, [['Staff'].includes(_vm.userRole) ? _c('button', {
      staticClass: "btn btn-primary btn-sm",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.edit(item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]) : _vm._e()]) : _vm._e()], 2);
  }), 0)])])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }